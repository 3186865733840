@import "../../import.scss";

.canvasWrapper {
  min-width: 0px;
  min-height: 0px;
  transition: flex-grow 1s, flex-shrink 1s;
  background: radial-gradient(#fdf8ef, #fcedd3);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  height: 130%;
  position: absolute;
  width: 100%;
  top: -30%;

  .canvas {
  }
  .logo {
    > img {
      height: 50px;
    }
  }
  .title {
    position: fixed;
    top: 0px;
    left: 24px;
    font-weight: bold;
    font-size: 130%;
    display: flex;
    left: 12px;
    right: 12px;
    z-index: 100;
    pointer-events: none;
    .sub {
      font-weight: 400;
      font-size: 70%;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
    }
  }
}
.overlay {
  pointer-events: none;
}
.tooltip {
  width: 200px;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 4px 1px #00000022;
  pointer-events: none;
  transform: translateX(-120%) translatey(-50%);

  .ttTitle {
    font-weight: bold;
    font-size: 110%;
    margin-bottom: 20px;
  }
  .ttImage {
    overflow: hidden;
    height: 150px;
    border-radius: 8px;
    margin-bottom: 20px;
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.circleButton {
  background-color: orange;
  opacity: 0.8;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  &.active {
    opacity: 1;
  }
  &:hover {
    opacity: 1;
  }
}
