$darkBlue: #000124;
$lightBlue: #00949a;

$text: #000028;
$lightText: #7d8099;
$blueText: #66b9ca;
$lines: #dfe6ed;
$darkLines: #b3b3be;
$sign-in-gradient: linear-gradient(180deg, $darkBlue, $lightBlue 100%);
$lightShadow: 0px 0px 8px 0px #00000022;
$orange: #ff9043;
$breakpoint: 700px;
.flex1 {
  flex: 1;
}

.headline {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 200%;
}
