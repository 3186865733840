@import "../../import.scss";

.inner {
  .logoWrapper {
    position: absolute;
    top: 20px;
    text-align: center;
    left: 0;
    right: 0;
    .logo {
      height: 50px;
    }
  }
  flex: 1;
  margin-left: 60px;
  margin-right: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  min-width: 0px;
  &.noMargin {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0;
  }
}
