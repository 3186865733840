@import "../../import.scss";

.tourController {
  padding: 12px;
  padding-bottom: 0;
  transition: flex-grow 1s, flex-shrink 1s;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: $lightShadow;
  background-color: white;
  min-height: 140px;
  padding-top: 0px;

  &.open {
  }
  .bottomShadow {
    pointer-events: none;
    height: 0px;
    width: 100%;
    .innerShadow {
      pointer-events: none;
      margin-left: 70px;
      background: linear-gradient(#ffffff00 0%, #ffffffff 70%, #ffffffff 100%);
      position: relative;
      top: -30px;
      height: 30px;
    }
  }
  .topShadow {
    pointer-events: none;
    height: 0px;
    width: 100%;
    .innerShadow {
      pointer-events: none;
      margin-left: 70px;
      background: linear-gradient(#ffffffff 0%, #ffffffff 40%, #ffffff00 100%);
      position: relative;
      z-index: 2;
      top: 0px;
      height: 20px;
      max-height: 20px;
    }
  }
  .taskList {
    max-height: 120px;
    margin-left: 70px;
    margin-top: 0px;
    overflow-y: auto;
  }
  .taskHeadline {
    position: absolute;
    bottom: 60%;
    left: 80px;
    font-weight: bold;
    font-size: 130%;
    .sub {
      font-size: 60%;
      font-weight: 400;
    }
  }

  .task {
    flex: 1;
    border-radius: 8px;
    margin-bottom: 4px;
    padding-left: 12px;
    padding-right: 12px;
    min-height: 30px;
    font-weight: bold;
    position: relative;
    display: flex;
    align-items: center;
    &.limitHeight {
      max-height: 60px;
    }
    .chevron {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .taskButtons {
    display: flex;
    height: 50px;
    margin-bottom: 8px;
    margin-top: 8px;
    .box {
      box-shadow: $lightShadow;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 3;
      .head {
        font-weight: bold;
      }
      .sub {
        font-size: 80%;
      }
    }
    .stepButton {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #eee;
      border-radius: 8px;
    }
  }

  .speakingCup {
    position: absolute;
    bottom: 60%;
    z-index: 1;
    margin-right: 12px;
    pointer-events: none;
    .bean {
      position: relative;
      top: 3px;
      margin-left: 18px;
      z-index: 10;
    }
    .speechBubble {
      background-color: white;
      box-shadow: $lightShadow;
      padding: 12px;
      border-radius: 12px;
      position: relative;
      .arrowBottom {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 30px solid transparent;

        border-top: 20px solid white;
        position: absolute;
        bottom: -10px;
        left: 40px;
      }
    }
  }
}
