@import "../../import.scss";

.tourViewer {
  display: flex;
  flex: 1;
  min-height: 0;
  min-width: 0;
  flex-direction: column;
  overflow: hidden;
  .loader {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: radial-gradient(#fdf8ef, #fcedd3);
    z-index: 1000;
    font-size: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #777;
    .logo {
      height: 50px;
      margin-bottom: 12px;
    }
 
  }
}

@media (min-width: $breakpoint) {
  .tourViewer {
  }
}
